import React, { useEffect } from "react";

import footericon1 from "../../../../assets/images/invoice/Group 69.svg";
import footericon2 from "../../../../assets/images/invoice/Group 72.svg";
import footericon3 from "../../../../assets/images/invoice/Group 75.svg";
import Locale from "../../../../translations";
import { printReceipts } from "../../../../services/admin";
import { useParams } from "react-router-dom";
import { useState } from "react";

export default function PrintInvoice() {
  const { translate } = Locale;
  const { id } = useParams();
  const { package_id } = useParams();
  const [data, setData] = useState();

  const [requiedNumber, setRequiedNumber] = useState(0);

  let test = [...Array(+88)];

  useEffect(async () => {
    const res = await printReceipts(id, package_id);
    if (res.status === 200) {
      setData(res.data.data);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (data?.packages?.length > 0) {
        document.title = "\u00A0";
        window.print();
        document.title = "React App";
      }
    }, 1000);
  }, [data?.packages?.length]);

  //real function
  useEffect(() => {
    debugger;
    if (+data?.packages?.length > 0 && +data?.packages?.length > 25) {
      let x = +data?.packages?.length - 25;
      for (let index = 0; index < +data?.packages.length; index++) {
        if (x > 0 || x === 0) {
          x = x - 27;
        } else {
          return setRequiedNumber(-x);
        }
      }
    }
  }, [data?.packages]);

  //test function
  /*      useEffect(() => {
    if (test?.length > 0 && test?.length > 25) {
      let x = +test?.length - 25;
      for (let index = 0; index < +test?.length; index++) {
        if (x > 0 || x === 0) {
          x = x - 27;
        } else {
         return setRequiedNumber(-x);
        }
      }
    }
  }, [data?.packages?.length]); 
 */

  const AllPayments = data?.packages && data?.packages.length > 0 && (
    <>
      {data?.packages &&
        data?.packages?.length > 0 &&
        data?.packages.map((item, index) => {
          return (
            <tr className="td-invoice" key={index}>
              <td>{index + 1}</td>
              <td>{item.package_no}</td>
              <td>{item.count}</td>
              <td>{item.departure_date}</td>
              <td> {item.totalPrice} </td>
            </tr>
          );
        })}

      <tr>
        <td
          colspan="2"
          className="procedures-invoice bg-table_invoice text-white font-weight-bold "
        >
          {" "}
          إجمالي العدد
        </td>
        <td colspan="4" className="bg-table_disable font-weight-bold">
          {data?.total_count} معتمر
        </td>
      </tr>

      {+data?.packages.length > 25 && requiedNumber != 0
        ? [...Array(+requiedNumber - 2)].map(() => {
            return (
              <tr className="td-invoice-extra">
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
                <td> </td>
              </tr>
            );
          })
        : null}
    </>
  );

  return (
    <div className="bg-white">
      <table className="invoice-holder mt-2 table-hight">
        <thead>
          <tr className="border border-bottom-0  ">
            <td className="p-0">
              <div className="invoice-container  border border-bottom-0 p-0 bg-white pb-3">
                <div className="d-flex justify-content-between ">
                  <figure className="invoice-logo-side p-4 ">
                    {/* <img src={side} alt="" className="w-100" /> */}
                  </figure>

                  <figure className="invoice-logo-main p-4 m-3 ">
                    {/* <img src={logo} alt="" className="w-100" /> */}
                  </figure>
                </div>
              </div>
            </td>
          </tr>
        </thead>
        <tbody className="bg-white">
          <tr>
            <td className="p-0">
              <div className="invoice-container border border-bottom-0  border-top-0 p-0 bg-white">
                <h2 className="text-center font-weight-bold  text-invoice mb-3">
                  بيان الفاتوره رقم : {data?.sn}
                </h2>

                <div className="px-4 mt-4 py-3">
                  <p className="font-weight-bold m-0">
                    <span className="text-invoice">عناية السادة/</span> غرفة
                    شركات السياحة
                  </p>

                  <p className="font-weight-bold m-0 ">
                    <span className="text-invoice"> وصف الخدمة/ </span> فحص PCR
                  </p>
                </div>
                <div className="px-4 fill-hight-new">
                  <table className="table m-0   ">
                    <thead className="">
                      <tr className="bg-table_invoice   ">
                        <th scope="col">#</th>
                        <th scope="col">رقم الرحلة</th>
                        <th scope="col">العدد</th>
                        <th scope="col">تاريخ الدخول الي المملكة</th>
                        <th scope="col">الإجمالي</th>
                      </tr>
                    </thead>

                    <tbody className="">
                      {AllPayments}

                      {/*               <div className="onPrint"></div> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot className="footer-width ">
          <td className="p-0 ">
            <div className="invoice-container border border-top-0 p-0 bg-white ">
              <div className="d-flex justify-content-between  pt-5">
                <figure className="invoice-logo-footer  m-0 p-4"></figure>

                <div className="d-flex  justify-content-around  px-4 pb-5 footer-width ">
                  <div className=" d-flex align-items-center ">
                    <div>
                      <p className="m-0 footer-invoice">
                        info@gpartnercorp.com
                      </p>
                      <p className="m-0 footer-invoice">
                        {" "}
                        www.gpartnercorp.com
                      </p>
                    </div>
                    <img
                      src={footericon1}
                      alt=""
                      className="icons-invoice mx-2"
                    />
                  </div>

                  <div className=" d-flex align-items-center ">
                    <div className=" d-flex flex-column  align-items-end  justify-content-end mx-2">
                      <p className="m-0 footer-invoice">
                        7222 - Elsafaa St. Al Madinah{" "}
                      </p>
                      <p className="m-0  "> Road Jeddah, KSA</p>
                    </div>
                    <img src={footericon2} alt="" className="icons-invoice " />
                  </div>

                  <div className="  d-flex align-items-center  ">
                    <p className="m-0 footer-invoice">+966 1 4507992 </p>

                    <img
                      src={footericon3}
                      alt=""
                      className="icons-invoice mx-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tfoot>
      </table>

      {/* new page */}
      <div className="invoice-holder border  p-0 bg-white pagebreak ">
        <div className="p-0">
          <div className="invoice-container  border border-bottom-0 p-0 bg-white pb-3 ">
            <div className="d-flex justify-content-between ">
              <figure className="invoice-logo-side p-4 ">
                {/* <img src={side} alt="" className="w-100" /> */}
              </figure>

              <figure className="invoice-logo-main p-4 m-3 ">
                {/* <img src={logo} alt="" className="w-100" /> */}
              </figure>
            </div>
          </div>
        </div>

        <h2 className="text-center font-weight-bold  text-invoice my-2">
          الفاتورة
        </h2>
        <div className="d-flex justify-content-center">
          <div>
            <div className="d-flex  justify-content-center ">
              <div className="text-center bg-invoice px-5 py-1   mr-1 ">
                <h6 className=" font-weight-bold">رقم الفاتورة</h6>
                <p className="m-0">{data?.sn}</p>
              </div>
              <div className="text-center bg-invoice px-5 py-1  mr-1">
                <h6 className=" font-weight-bold"> التاريخ</h6>
                <p className="m-0">{data?.created_at} </p>
              </div>
              <div className="text-center bg-invoice px-5 py-1  mr-1">
                <h6 className=" font-weight-bold"> سجل تجاري</h6>
                <p className="m-0">{data?.commercial_register}</p>
              </div>
              <div className="text-center bg-invoice px-5 py-1  ">
                <h6 className=" font-weight-bold"> بطاقة ضريبية</h6>
                <p className="m-0">{data?.tax_card}</p>
              </div>
            </div>
            <div className="mt-3 w-50">
              <p className="font-weight-bold m-0">
                <span className="text-invoice">عناية السادة/</span> غرفة شركات
                السياحة
              </p>

              <p className="font-weight-bold m-0 ">
                <span className="text-invoice"> وصف الخدمة/ </span> فحص PCR
              </p>
            </div>
            <div className="fill-hight-new">
              <table className="table mt-3 ">
                <thead className="">
                  <tr className="bg-table_invoice   ">
                    <th scope="col">البيان</th>
                    <th scope="col">العدد</th>
                    <th scope="col"> التكلفه </th>
                    <th scope="col">الإجمالي</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="td-invoice">
                    <td className="">
                      {" "}
                      إجمالي الإجراءات والعمليات بالمملكة العربية السعودية
                    </td>
                    <td
                      className="  text-center"
                      rowSpan={2}
                      style={{ verticalAlign: "middle" }}
                    >
                      {data?.total_count}
                    </td>
                    <td className="">130 رس</td>
                    <td className=" ">
                      {Number.parseFloat(data?.total_count) * +130} ر.س
                    </td>
                  </tr>

                  <tr className="td-invoice">
                    <td className="">
                      {" "}
                      تكلفة إجراءات الوكيل في جمهورية مصر العربيه
                    </td>

                    <td className=" ">4.386 رس</td>
                    <td className=" ">
                      {Number.parseFloat(data?.total_count) * 4.386} ر.س
                    </td>
                  </tr>
                  <tr className="td-invoice">
                    <td className=""> خصم ضربية أرباح تجاية و صناعيه</td>
                    <td
                      className=" text-center"
                      colSpan={2}
                      style={{ verticalAlign: "middle" }}
                    >
                      3%
                    </td>

                    <td className=" ">
                      {(
                        Number.parseFloat(data?.total_count) *
                        4.386 *
                        (3 / 100)
                      ).toFixed(3)}{" "}
                      ر.س
                    </td>
                  </tr>
                  <tr className="td-invoice">
                    <td className="">
                      {" "}
                      القيمة المضافة على تكلفة إجراءات الوكيل بجمهورية مصر
                      العربية
                    </td>
                    <td
                      className="  text-center"
                      colSpan={2}
                      style={{ verticalAlign: "middle" }}
                    >
                      14%
                    </td>

                    <td className=" ">
                      {" "}
                      {(
                        Number.parseFloat(data?.total_count) *
                        4.386 *
                        (14 / 100)
                      ).toFixed(3)}{" "}
                      ر.س
                    </td>
                  </tr>
                  <tr className="td-invoice">
                    <td colspan="3" className="procedures-invoice ">
                      {" "}
                      الإجمالي بالريال السعودي
                    </td>
                    <td className=" font-weight-bold ">
                      {(
                        Number.parseFloat(data?.total_count) * 130 +
                        Number.parseFloat(data?.total_count) * 4.386 +
                        Number.parseFloat(data?.total_count) *
                          4.386 *
                          (14 / 100) -
                        Number.parseFloat(data?.total_count) * 4.386 * (3 / 100)
                      ).toFixed(3)}
                      ر.س
                    </td>
                  </tr>

                  <tr className="td-invoice">
                    <td colspan="3" className="procedures-invoice">
                      {" "}
                      سعر التحويل ببنك مصر
                    </td>
                    <td className=" font-weight-bold">
                      {data?.prices?.exchange_factor}
                    </td>
                  </tr>
                  <tr className="td-invoice">
                    <td colspan="3" className="procedures-invoice  ">
                      {" "}
                      إجمالي السعر بالجنيه المصري
                    </td>
                    <td className=" font-weight-bold  ">
                      {((Number.parseFloat(data?.total_count) * 130 +
                        Number.parseFloat(data?.total_count) * 4.386 +
                        Number.parseFloat(data?.total_count) *
                          4.386 *
                          (14 / 100) -
                        Number.parseFloat(data?.total_count) *
                          4.386 *
                          (3 / 100)) *
                        Number.parseFloat(data?.prices?.exchange_factor)).toFixed(3)}

                      {}
                    </td>
                  </tr>

                  <div className="mt-5 ">
                    <p className="font-weight-bold m-0">
                      <span className="text-invoice">تفاصيل و شروط الدفع </span>{" "}
                    </p>

                    <p className="font-weight-bold m-0 ">
                      <span className="sadd_bank">
                        السداد عبر بيانات البنك التاليه :
                      </span>{" "}
                    </p>

                    <p className=" m-0 mt-2 ">
                      <span className="font-weight-bold"> البنك : </span>{" "}
                      <span>بنك مصر</span>
                    </p>

                    <p className=" m-0 ">
                      <span className="font-weight-bold"> الفرع : </span>{" "}
                      <span>كايرو فيستيفال</span>
                    </p>

                    <p className=" m-0 ">
                      <span className="font-weight-bold"> حساب رقم : </span>{" "}
                      <span>5010001000000102</span>
                    </p>

                    <p className=" m-0 ">
                      <span className="font-weight-bold"> آيبان : </span>{" "}
                      <span> EG900002050105010001000000102</span>
                    </p>

                    <p className=" m-0 ">
                      <span className="font-weight-bold"> سويفت : </span>{" "}
                      <span>BMISEGCXXXX</span>
                    </p>

                    <p className=" m-0 ">
                      <span className="font-weight-bold"> المستفيد : </span>{" "}
                      <span>شركة شريك النمو</span>
                    </p>
                  </div>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="invoice-container border border-top-0 p-0 bg-white ">
          <div className="d-flex justify-content-between  pt-5">
            <figure className="invoice-logo-footer  m-0 p-4"></figure>

            <div className="d-flex  justify-content-around  px-4 pb-5 footer-width ">
              <div className=" d-flex align-items-center ">
                <div>
                  <p className="m-0 footer-invoice">info@gpartnercorp.com</p>
                  <p className="m-0 footer-invoice"> www.gpartnercorp.com</p>
                </div>
                <img src={footericon1} alt="" className="icons-invoice mx-2" />
              </div>

              <div className=" d-flex align-items-center ">
                <div className=" d-flex flex-column  align-items-end  justify-content-end mx-2">
                  <p className="m-0 footer-invoice">
                    7222 - Elsafaa St. Al Madinah{" "}
                  </p>
                  <p className="m-0  "> Road Jeddah, KSA</p>
                </div>
                <img src={footericon2} alt="" className="icons-invoice " />
              </div>

              <div className="  d-flex align-items-center  ">
                <p className="m-0 footer-invoice">+966 1 4507992 </p>

                <img src={footericon3} alt="" className="icons-invoice mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
