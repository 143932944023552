import Locale from "../../../translations";
import Request from "./Request";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useCompanyState } from "../../../context/global";
import SelectField from "../../../components/shared/SelectField";
import TimeDatePicker from "../../../components/shared/TimeDatePicker";
import {
  createOperationOrder,
  listEmployees,
} from "../../../services/UmrahRequests";
import moment from "moment";
import { store } from "react-notifications-component";
import { addCompaniesPackages, listCompanies } from "../../../services/admin";

export default function ListRequests({ listRequests, setSearching }) {
  let myFutureDate = new Date();
  myFutureDate.setDate(myFutureDate.getDate() + 5);

  const { translate } = Locale;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenGroup, setIsOpenGroup] = useState(false);
  const { currentLanguage } = useCompanyState();

  const toggle = (operationData = null) => {
    setIsOpen(!isOpen);

    setOperation({
      ...operation,
      ...operationData,
      package_id: operationData?.id?.toString(),
      id: null,
    });
  };

  const toggleGroup = () => {
    setIsOpenGroup(!isOpenGroup);

    // setOperation({
    //   ...operation,
    //   ...operationData,
    //   package_id: operationData?.id?.toString(),
    //   id: null,
    // });
  };
  

    const handleChecks = (e) => {
      
      const checked = e.target.checked;
      const value = +e.target.value;
      // const day = days[value];
      if (checked) {
        setOperation({ ...operation, packages_ids: [...operation?.packages_ids, value] });
      } else {
        const work_daysClone = [...operation.packages_ids];
        const filtered = work_daysClone.filter((d) => d !== value);
        setOperation({ ...operation, packages_ids: filtered });
    
      }

      console.log(operation);
    };
    
  

  const [companies, setCompanies] = useState();

  const [operation, setOperation] = useState({
    company_id: null,
    check_date: null,
    check_time: null,
    display_date: new Date(),
    packages_ids:[]
  });

  useEffect(async () => {
    const res = await listCompanies();
    
    const formatted = res?.companies?.map((employee) => {
      
      return { value: employee.id, label: employee.name };
    });
    setCompanies(formatted);
  }, []);

  const allRequests =
    listRequests?.length > 0 &&
    listRequests?.map((request) => {
      return <Request request={request} toggle={toggle} handleChecks={handleChecks} operation={operation} />;
    });

  const createOperation = async () => {
  
    const res = await addCompaniesPackages({
      company_id: operation.company_id.value,
      packages_ids: [operation.package_id],
    });

    toggle();
    setSearching((prev) => !prev);
    
    if (res?.message == "تم التعديل بنجاح") {
      store.addNotification({
        title: "info!",
        message: "تم التعديل بنجاح",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };
  const createOperationGroup = async () => {
  
    const res = await addCompaniesPackages({
      company_id: operation.company_id.value,
      packages_ids: operation.packages_ids,
    });

    setIsOpenGroup(!isOpenGroup);

    setSearching((prev) => !prev);
    
    if (res?.message == "تم التعديل بنجاح") {
      store.addNotification({
        title: "info!",
        message: "تم التعديل بنجاح",
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };
  return (
    <>

{operation&&operation.packages_ids&&operation.packages_ids.length>0?
 <div>
      <button onClick={toggleGroup}  className="btn btn-success mb-1">توريد مجموعة رحلات</button>
    </div>:''}
  
      <table className="table-responsive-lg table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
          <td></td>
            <th scope="col"> {translate.TripName} </th>
            <th scope="col">{translate.companyName}</th>

            <th scope="col">{translate.passengerNumber}</th>
            <th scope="col">{translate.hotelName}</th>
            <th scope="col">اسم شركة المعمل</th>
            <th scope="col">{translate.dateInOut}</th>
            <th scope="col">{translate.city}</th>
            <th scope="col">{translate.SupervisorName}</th>
            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {allRequests?.length > 0 ? (
            allRequests
          ) : (
            <tr>
              <td colSpan="10" className="text-center my-3">
                <div className="product-build__product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle({
              employee_id: null,
              check_date: null,
              check_time: null,
              display_date: new Date(),
            });
          }}
        >
          <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
            <p className="h3 text-secondary ">{translate.runOrder}</p>
            <i
              className="far fa-times-circle fa-lg text-secondary "
              onClick={() => {
                toggle({
                  employee_id: null,
                  check_date: null,
                  check_time: null,
                  display_date: new Date(),
                });
              }}
            ></i>
          </div>

          <ModalBody className="lan  d-flex flex-column justify-content-center align-items-center ">
            <div
              className="mb-4 w-50"
              style={{
                textAlign: currentLanguage === "ar" ? "right" : "left",
              }}
            >
              <SelectField
                hasLabel={true}
                onFocus={false}
                placeholder={translate.companyName}
                label={translate.companyName}
                value={operation.company_id}
                options={companies}
                onChange={(e) => {
                  setOperation({
                    ...operation,
                    company_id: e,
                  });
                }}
              />
            </div>

            
          </ModalBody>
          <div className="pb-5 border-top-0  text-center    p-0 ">
            <button
              className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
              onClick={createOperation}
            >
             توريد
            </button>
          </div>
        </Modal>

        <Modal
          isOpen={isOpenGroup}
          toggle={() => {
            toggleGroup()
          }}
        >
          <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
            <p className="h3 text-secondary ">{translate.runOrder}</p>
            <i
              className="far fa-times-circle fa-lg text-secondary "
              onClick={() => {
                toggleGroup()
                setOperation({
                  ...operation,
                  company_id: null,
                });
              }}
            ></i>
          </div>

          <ModalBody className="lan  d-flex flex-column justify-content-center align-items-center ">
            <div
              className="mb-4 w-50"
              style={{
                textAlign: currentLanguage === "ar" ? "right" : "left",
              }}
            >
              <SelectField
                hasLabel={true}
                onFocus={false}
                placeholder={translate.companyName}
                label={translate.companyName}
                value={operation.company_id}
                options={companies}
                onChange={(e) => {
                  setOperation({
                    ...operation,
                    company_id: e,
                  });
                }}
              />
            </div>

            
          </ModalBody>
          <div className="pb-5 border-top-0  text-center    p-0 ">
            <button
              className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
              onClick={createOperationGroup}
            >
             توريد
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}
