import React, { useState } from "react";
import Locale from "../../../translations";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import ShowForPermission from "../../../helpers/showForPermission";
import { useCompanyState } from "../../../context/global";
import { Tooltip } from "reactstrap";

export default function Request({ request, toggle, index }) {
  const { translate } = Locale;
  const { status } = useParams();
  const { currentLanguage } = useCompanyState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenNo, setTooltipOpeNo] = useState(false);

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };
  const toggleTooltipNo = () => {
    setTooltipOpeNo(!tooltipOpenNo);
  };

  return (
    <tr className="lan">
      <td>{request?.package_name}</td>
      <td>
        {request?.company_name[currentLanguage] != undefined
          ? request?.company_name[currentLanguage]
          : request?.company_name.ar}
      </td>
      <td>
        {status === "orders" ? request?.travellers_count : request?.travellers}

        {status === "pending" ? (
          <Tooltip
            placement="top"
            isOpen={tooltipOpen}
            target={`TooltipExample${index}`}
            toggle={toggleTooltip}
          >
            <span className="lan  font-weight-bold">
              {translate.haveResult}
            </span>
          </Tooltip>
        ) : null}

        {status === "pending" ? (
          <Tooltip
            placement="top"
            isOpen={tooltipOpenNo}
            target={`TooltipExampleNo${index}`}
            toggle={toggleTooltipNo}
          >
            <span className="lan  font-weight-bold">
              {translate.haveNoResult}
            </span>
          </Tooltip>
        ) : null}

        {status === "pending" ? (
          <span className="mx-2">
            (
            <span
              className="text-success mx-1 font-weight-bold"
              id={`TooltipExample${index}`}
            >
              {request.checked_travellers}
            </span>
            /
            <span
              className="text-danger mx-1 font-weight-bold"
              id={`TooltipExampleNo${index}`}
            >
              {request.unchecked_travellers}
            </span>
            )
          </span>
        ) : null}
      </td>
      <td>{request?.hotel?.name}</td>
      <td>
        <p className="text-success font-weight-bold m-0">
          {request?.hotel?.entry_date
            ? moment(request?.hotel?.entry_date, "DD-MM-YYYY HH:mm aa").format(
                "DD-MM-YYYY"
              )
            : " "}
        </p>
      </td>
      <td>
        <p className="text-danger font-weight-bold">
          {request?.hotel?.exit_date
            ? moment(request?.hotel?.exit_date, "DD-MM-YYYY HH:mm aa").format(
                "DD-MM-YYYY"
              )
            : " "}
        </p>
      </td>

      <td>{request?.hotel?.city}</td>
      <td>{request?.SaSupervisor?.name}</td>

      {status != "orders" ? (
        <td>
          {" "}
          {request?.check_date
            ? moment(request?.check_date, "YYYY-MM-DD HH:mm aa").format(
                "DD-MM-YYYY   HH:mm"
              )
            : " "}
        </td>
      ) : null}

      <td>
        <ShowForPermission permission={"company:view-traveller"}>
          {status === "orders" ? (
            <Link
              className="btn text-primary font-weight-bold"
              to={`/Umrah/Medical/Requests/view/${request.id}`}
            >
              {translate.view}
            </Link>
          ) : status === "pending" ? (
            <Link
              className="btn text-primary font-weight-bold"
              to={`/Umrah/Medical/Requests/view/${request.id}/pending`}
            >
              {translate.view}
            </Link>
          ) : (
            <Link
              className="btn text-primary font-weight-bold"
              to={`/Umrah/Medical/Requests/view/${request.id}/checked`}
            >
              {translate.view}
            </Link>
          )}
        </ShowForPermission>

        <ShowForPermission permission={"company:submit-operation-order"}>
          {status === "orders" ? (
            <button
              className="btn text-primary font-weight-bold"
              onClick={() => {
                toggle(request);
              }}
            >
              {translate.SupplyingRunningOrder}
            </button>
          ) : null}
        </ShowForPermission>
      </td>
    </tr>
  );
}
