import Locale from "../../../translations";
import Request from "./Request";
import { Modal, ModalFooter, ModalBody } from "reactstrap";
import React, { useEffect, useState } from "react";
import { useCompanyState } from "../../../context/global";
import SelectField from "../../../components/shared/SelectField";
import TextAreaField from "../../../components/shared/TextAreaField";
import TimeDatePicker from "../../../components/shared/TimeDatePicker";
import {
  createOperationOrder,
  listEmployees,
} from "../../../services/UmrahRequests";
import moment from "moment";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function ListRequests({ listRequests, setSearching }) {
  let myFutureDate = new Date();
  myFutureDate.setDate(myFutureDate.getDate() + 5);
  const { status } = useParams();

  const { translate } = Locale;
  const [isOpen, setIsOpen] = useState(false);
  const { currentLanguage } = useCompanyState();

  const toggle = (operationData = null) => {
    setIsOpen(!isOpen);

    setOperation({
      ...operation,
      ...operationData,
      package_id: operationData?.id?.toString(),
      id: null,
    });
  };

  const [employees, setEmployees] = useState();

  const [operation, setOperation] = useState({
    employee_id: null,
    check_date: null,
    check_time: null,
    display_date: new Date(),
  });

  useEffect(async () => {
    const res = await listEmployees();
    const formatted = res?.map((employee) => {
      return { value: employee.id, label: employee.name };
    });
    setEmployees(formatted);
  }, []);

  const allRequests =
    listRequests?.length > 0 &&
    listRequests?.map((request ,index) => {
      return <Request request={request} toggle={toggle} index={index} />;
    });

  const createOperation = async () => {
    const res = await createOperationOrder({
      ...operation,
      employee_id: operation.employee_id.value,
      display_date: null,
    });

    toggle();
    setSearching((prev) => !prev);
    if (res?.status === 200) {
      store.addNotification({
        title: "info!",
        message: res.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };

  return (
    <>
      <table className="table-responsive-lg table table-travelers">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col"> {translate.TripName} </th>
            <th scope="col">{translate.companyName}</th>
            <th scope="col">{translate.passengerNumber}</th>
            <th scope="col">{translate.hotelName}</th>
            <th scope="col">{translate.dateIn}</th>
            <th scope="col">{translate.dateOut}</th>

            <th scope="col">{translate.city}</th>
            <th scope="col">{translate.SupervisorName}</th>

            {status != "orders" ? (
              <th scope="col">{translate.DateRequest}</th>
            ) : null}

            <th scope="col">{translate.processes}</th>
          </tr>
        </thead>
        <tbody>
          {allRequests?.length > 0 ? (
            allRequests
          ) : (
            <tr>
              <td colSpan="10" className="text-center my-3">
                <div className="product-build__product-no-data">
                  <i className="fas fa-info-circle fa-lg"></i>{" "}
                  <h4>{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      <div>
        <Modal
          isOpen={isOpen}
          toggle={() => {
            toggle({
              employee_id: null,
              check_date: null,
              check_time: null,
              display_date: new Date(),
            });
          }}
        >
          <div className="lan d-flex justify-content-between align-items-baseline p-3 border-bottom ">
            <p className="h3 text-secondary ">{translate.runOrder}</p>
            <i
              className="far fa-times-circle fa-lg text-secondary "
              onClick={() => {
                toggle({
                  employee_id: null,
                  check_date: null,
                  check_time: null,
                  display_date: new Date(),
                });
              }}
            ></i>
          </div>

          <ModalBody className="lan  d-flex flex-column justify-content-center align-items-center ">
            <div
              className=" w-50"
              style={{
                textAlign: currentLanguage === "ar" ? "right" : "left",
              }}
            >
              <SelectField
                hasLabel={true}
                onFocus={false}
                placeholder={translate.employeeNamePlaceholder}
                label={translate.employeeName}
                value={operation.employee_id}
                options={employees}
                onChange={(e) => {
                  setOperation({
                    ...operation,
                    employee_id: e,
                  });
                }}
              />
            </div>

            <div className="w-50 border rounded my-5">
              <textarea
                className="border-0 w-100 p-2"
                onChange={(e) => {
                  setOperation({
                    ...operation,
                    comment: e.target.value,
                  });
                }}
                placeholder={translate.comments}
                value={operation.comment}
              ></textarea>
            </div>

            <div className="w-50">
              <TimeDatePicker
                hasLabel={true}
                label={translate.examinationDate}
                placeholder={translate.examinationDatePlaceholder}
                name="examination_date"
                date={operation.display_date}
                minDateTime={new Date()}
                maxDateTime={myFutureDate}
                onChangeDate={(e) => {
                  setOperation({
                    ...operation,
                    display_date: e,
                    check_date: e
                      ? moment(e, "YYYY-MM-DD HH:mm aa").format("YYYY-MM-DD")
                      : " ",
                    check_time: e
                      ? moment(e, "YYYY-MM-DD HH:mm aa").format("HH:mm ")
                      : " ",
                  });
                }}
              ></TimeDatePicker>
            </div>
          </ModalBody>
          <div className="pb-5 border-top-0  text-center    p-0 ">
            <button
              className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
              onClick={createOperation}
            >
              {translate.printRunOrder}
            </button>
          </div>
        </Modal>
      </div>
    </>
  );
}
