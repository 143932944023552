import React from "react";
import Locale from "../../../../translations";
import Payment from "./Payment";

export default function ListPayments({ payments, packages_ids }) {
  const { translate } = Locale;
  const AllPayments =
    payments &&
    payments.length > 0 &&
    payments.map((payment, index) => {
      return <Payment payment={payment} key={index} />;
    });

  return (
    <div className="">
      <table className="table table-responsive-sm">
        <thead className="bg_table ">
          <tr className="table_head">
            <th scope="col">{translate.companyName}</th>
            <th scope="col">{translate.licenseNumber}</th>
            <th scope="col">{translate.pilgrimNumbers}</th>
            <th scope="col">{translate.returnDate}</th>
            <th scope="col">{translate.DepartureDate}</th>

            <th scope="col">{translate.hotelName}</th>
            <th scope="col">{translate.status}</th>
          </tr>
        </thead>
        <tbody>
          {AllPayments && AllPayments.length > 0 ? (
            AllPayments
          ) : (
            <tr>
              <td colSpan="11">
                <div className=" text-center">
                  <i className="fas fa-info-circle fa-lg text-muted m-3 "></i>{" "}
                  <h4 className=" text-muted">{translate.noResult}</h4>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/*       <Pagination /> */}
    </div>
  );
}
