import React, { useEffect, useState } from "react";
import {
  ChangeExaminationDate,
  exportExecl,
  saveReservationResult,
  viewPendingTravelers,
  viewTravelers,
} from "../../../services/UmrahRequests";
import { useParams } from "react-router";
import Locale from "../../../translations";
import ViewTraveller from "./ViewTraveller";
import { Link } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import SelectField from "../../../components/shared/SelectField";
import { useCompanyState } from "../../../context/global";
import { store } from "react-notifications-component";
import moment from "moment";
import TimeDatePicker from "../../../components/shared/TimeDatePicker";
import TextField from "../../../components/shared/textField";

export default function ViewTravellers() {
  const { id, status } = useParams();
  const { currentLanguage } = useCompanyState();
  const [saving, setSaving] = useState(false);
  const { translate } = Locale;
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDate, setIsOpenDate] = useState(false);

  const token = localStorage.getItem("token");

  const [passport, setPassport] = useState();

  const [operation, setOperation] = useState({
    employee_id: null,
    check_date: null,
    check_time: null,
    display_date: new Date(),
  });

  const [result, setResult] = useState({
    file: null,
    status: null,
    id: null,
  });

  const optionsTest = [
    { value: "positive", label: translate.positive },
    { value: "negative", label: translate.negative },
  ];

  let myFutureDate = new Date();
  myFutureDate.setDate(myFutureDate.getDate() + 5);

  const openExcel = async () => {
    const res = await exportExecl(
      {
        Authorization: token,
      },
      id
    );
    if (res) {
      const url = window.URL.createObjectURL(new Blob([res]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "file.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  };

  const toggle = (id = null) => {
    setIsOpen(!isOpen);
    setResult({
      ...result,
      id: id,
    });
  };

  const toggleDate = (id = null) => {
    setIsOpenDate(!isOpenDate);
    setOperation({
      ...operation,
      id,
    });
  };

  const [trip, settrip] = useState();
  const [travellers, setTravellers] = useState();

  const saveResult = async () => {
    const formData = new FormData();
    console.log(result);
    formData.append("file", result.file);
    formData.append("status", result.status.value);

    const res = await saveReservationResult(result.id, formData);
    toggle();
    setResult({ file: null, status: null, id: null });

    if (res?.message) {
      
      store.addNotification({
        title: "info!",
        message: res.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
    setSaving(!saving);
  };

  useEffect(async () => {
    let res;
  
    if (status) {
     
      res = await viewPendingTravelers(id, {});
      setTravellers(res?.reservations);
    } else {
      
      res = await viewTravelers(id);
      setTravellers(res?.travellers);
    }
    settrip(res);
  }, [saving]);

  const changeOperationDate = async () => {
    const res = await ChangeExaminationDate(
      {
        check_date: operation.check_date,
        check_time: operation.check_time,
      },
      operation.id
    );
    if (res?.data?.id) {
      store.addNotification({
        title: "info!",
        message: `${translate.ChangeExaminationDateMassage}`,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }

    toggleDate();
    setOperation({});
    setSaving(!saving);
  };

  const searchView = async (passport) => {
    const res = await viewPendingTravelers(id, { passport_num: passport });
    setTravellers(res?.reservations);
  };

  const AllClients =
    travellers &&
    travellers.length > 0 &&
    travellers.map((data, index) => (
      <ViewTraveller
        data={data}
        key={index}
        index={index}
        toggle={toggle}
        toggleDate={toggleDate}
      />
    ));

  return (
    <>
      <div className="container container bg-white py-3 mt-2">
        <div className="py-2 p-3  border instructions rounded mt-3 lan">
          <div className="d-flex justify-content-between">
            <h5 className="font-weight-bold text_blue-dark mt-2">
              {translate.TripData} / {translate.hotelData}
            </h5>

            {status ? (
              <button className="btn btn-success ">
                <i class="fas fa-file-excel fa-2x " onClick={openExcel}></i>
              </button>
            ) : null}
          </div>

          <div className="row">
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.TripNumber}
            </p>
            {status ? (
              <p className="col-6  col-md-4  font-mobile  h5">
                {trip?.package_id}
              </p>
            ) : (
              <p className="col-6  col-md-4  font-mobile  h5">{trip?.id}</p>
            )}

            <p className="col-6  col-md-2  font-mobile h5 font-weight-bold">
              {translate.TripNames}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5 ">
              {trip?.package_name}
            </p>
            {/*       <p className="col-6  col-md-2  font-mobile h5  font-weight-bold">
              {translate.returnDate}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5">
              {" "}
              {trip?.return_date}
            </p> */}
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.hotelName}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {" "}
              {trip?.hotel?.name}
            </p>
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.address}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.address}
            </p>
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.city}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.city}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.area}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.area}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.dateIn}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.entry_date}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.dateOut}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5   ">
              {trip?.hotel?.exit_date}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.returnAirport}
            </p>

            {status ? (
              <p className="col-6  col-md-4  font-mobile  h5    ">
                {trip?.return_from_port}
              </p>
            ) : (
              <p className="col-6  col-md-4  font-mobile  h5    ">
                {trip?.returnFromPort}
              </p>
            )}
          </div>
        </div>

        <div className="py-2 p-3  border instructions rounded mt-3">
          <h5 className="font-weight-bold text_blue-dark mt-2">
            {translate.SupervisorData}
          </h5>
          <div className="row">
            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.SupervisorName}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.name}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.PhoneEG}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.egypt_phone}
            </p>

            <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
              {translate.PhoneSaudi}
            </p>
            <p className="col-6  col-md-4  font-mobile  h5    ">
              {trip?.SaSupervisor?.saudi_phone}
            </p>
          </div>
        </div>

        {status ? (
          <div className="py-2 p-3  border instructions rounded mt-3 lan">
            <div className="row">
              <p className="col-6  col-md-2  font-mobile h5   font-weight-bold">
                {translate.notes} :
              </p>
              <p className="col-6  col-md-4  font-mobile  h5    ">
                {trip?.operationOrder_comment}
              </p>
            </div>
          </div>
        ) : null}

        {status === "pending" ? (
          <div className="py-2 p-3 d-flex justify-content-between  border list_header rounded mt-3">
            <div className="d-flex">
              <h5 className="font-weight-bold text_blue-dark ">
                {translate.totalResults} :
              </h5>
              <h5 className="font-weight-bold text-primary  mx-2">
                {trip?.travellers}
              </h5>
            </div>
            <div className="d-flex">
              <h5 className="font-weight-bold text_blue-dark ">
                {translate.haveResult} :
              </h5>
              <h5 className="font-weight-bold text-success  mx-2">
                {trip?.hasResult}
              </h5>
            </div>
            <div className="d-flex">
              <h5 className="font-weight-bold text_blue-dark  mx-2">
                {translate.haveNoResult} :
              </h5>
              <h5 className="font-weight-bold text-danger ">
                {trip?.withoutResult}
              </h5>
            </div>
          </div>
        ) : null}

        {/* filter view clients */}
        {status === "pending" ? (
          <div className="d-flex justify-content-between mt-4">
            <div className="">
              <TextField
                hasLabel={false}
                placeholder={translate.passportNumberPlaceHolder}
                onChange={(e) => {
                  setPassport(e.target.value);
                }}
                value={passport}
              >
                <i className="fas fa-search"></i>
              </TextField>
            </div>
            <div className="  mx-4">
              <button
                type="button"
                class="btn btn-primary px-5 mx-4"
                onClick={() => {
                  searchView(passport);
                }}
              >
                {translate.search}
              </button>

              <button
                className=" text-muted font-weight-bold "
                onClick={() => {
                  setPassport("");
                  searchView(null);
                }}
              >
                {" "}
                {translate.reset} <i className="fas fa-retweet"></i>
              </button>
            </div>
          </div>
        ) : null}

        {AllClients?.length > 0 ? (
          AllClients
        ) : (
          <h2 className="text-center my-4 text-secondary border rounded py-5 instructions">
            {translate.noResult} <i className="fas fa-info-circle fa-lg"></i>{" "}
          </h2>
        )}

        {status === "checked" ? (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to={"/Umrah/Medical/Requests/checked"}
          >
            {translate.back}
          </Link>
        ) : status === "pending" ? (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to={"/Umrah/Medical/Requests/pending"}
          >
            {translate.back}
          </Link>
        ) : (
          <Link
            className="btn btn-secondary px-4 mt-5"
            to={"/Umrah/Medical/Requests/orders"}
          >
            {translate.back}
          </Link>
        )}
      </div>

      <Modal
        isOpen={isOpen}
        toggle={() => {
          toggle();
          setResult({ file: null, status: null, id: null });
        }}
        className="lan "
      >
        <div className="lan d-flex justify-content-center align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary">{translate.addResult}</p>
        </div>
        <ModalBody className=" d-flex flex-column align-items-center py-4">
          <div
            className="mb-4 w-50 "
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <SelectField
              hasLabel={true}
              onFocus={false}
              placeholder={translate.examinationStatus}
              label={translate.status}
              value={result.status}
              options={optionsTest}
              onChange={(e) => {
                setResult({
                  ...result,
                  status: e,
                });
              }}
            />
          </div>
          <div className="border w-50 mt-3 p-3 rounded">
            <input
              required
              accept="application/pdf, .pdf"
              type="file"
              id="file"
              onChange={(e) => {
                setResult({ ...result, file: e.target.files[0] });
              }}
            />
          </div>
        </ModalBody>
        <div className="pb-5 border-top-0  text-center    p-0 ">
          <button
            className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
            onClick={saveResult}
          >
            {translate.addResult}
          </button>
        </div>
      </Modal>

      {/* change date modal */}

      <Modal
        isOpen={isOpenDate}
        toggle={() => {
          toggleDate();
          setOperation({});
        }}
        className="lan "
      >
        <div className="lan d-flex justify-content-center align-items-baseline p-3 border-bottom ">
          <p className="h3 text-secondary">{translate.ChangeExaminationDate}</p>
        </div>
        <ModalBody className=" d-flex flex-column align-items-center justify-content-center ">
          <div
            className="w-50 "
            style={{
              textAlign: currentLanguage === "ar" ? "right" : "left",
            }}
          >
            <div className="">
              <TimeDatePicker
                hasLabel={true}
                label={translate.examinationDate}
                placeholder={translate.examinationDatePlaceholder}
                name="examination_date"
                date={operation.display_date}
                minDateTime={new Date()}
                maxDateTime={myFutureDate}
                onChangeDate={(e) => {
                  setOperation({
                    ...operation,
                    display_date: e,
                    check_date: e
                      ? moment(e, "YYYY-MM-DD HH:mm aa").format("YYYY-MM-DD")
                      : " ",
                    check_time: e
                      ? moment(e, "YYYY-MM-DD HH:mm aa").format("HH:mm ")
                      : " ",
                  });
                }}
              ></TimeDatePicker>
            </div>
          </div>
        </ModalBody>
        <div className="pb-5 border-top-0  text-center    p-0 ">
          <button
            className="rounded btn-success w-50 lan text-bold h5 py-2 m-0 "
            onClick={changeOperationDate}
          >
            {translate.ChangeExaminationDate}
          </button>
        </div>
      </Modal>
    </>
  );
}
