import axios from "axios";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL_ADMIN;
const http = process.env.REACT_APP_ADMIN_HTTP;
const [protocol, domain] = baseURL.split("//");
const host = window.location.host;
const subdomain = host != null ? host.split(".")[0] : null;

export const AdminURL = http + `://${domain}/api/central`;

//*****************************Admin Login************************

export const singin = async (data) => {
  return await axios
    .post(`${AdminURL}/auth/login`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
//*****************************Admin************************

//labs/dashboard
export const dashboard = async () => {
  return await axios
    .get(`${AdminURL}/home`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const listAdmin = async () => {
  return await axios
    .get(`${AdminURL}/companies`)
    .then((res) => res)
    .catch((err) => err.response);
};
///api/central/companies?search=&status
export const listAdminFilter = async (page = 1, filter) => {
  return await axios
    .get(`${AdminURL}/companies?page= ${page}`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const AdminchangeStatus = async (id, status) => {
  return await axios
    .patch(`${AdminURL}/companies/${id}/${status}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const viewCompany = async (id) => {
  return await axios
    .get(`${AdminURL}/companies/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const addCompany = async (data) => {
  return await axios
    .post(`${AdminURL}/companies`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const fetchCountries = async () => {
  return await axios
    .get(`${AdminURL}/lookups/countries`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const fetchCities = async (id) => {
  return await axios
    .get(`${AdminURL}/lookups/cities?country_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};
//companies
export const companiesDrop = async () => {
  return await axios
    .get(`${AdminURL}/companies/create`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const editCompany = async (id, data) => {
  return await axios
    .put(`${AdminURL}/companies/${id}`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

/*******************Orders*********************** */
export const listIcomingOrder = async (id, filter) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/travellers`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};
export const viewIcomingOrder = async (id, index) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/travellers/${index}`)
    .then((res) => res)
    .catch((err) => err.response);
};
//companies/1/travellers/pending
export const listPendingOrder = async (id, filter) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/travellers/pending`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const listCheckedOrder = async (id, filter) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/travellers/checked`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};
//************payment************* */
export const paymentNumber = async (id) => {
  console.log("centralUrlcentralUrl", AdminURL);
  return await axios
    .get(`${AdminURL}/admins/settlements-numbers/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};
export const paymentList = async (id, page = 1, filter) => {
  return await axios
    .get(`${AdminURL}/admins/settlements/${id}`, {
      params: cleanEmpty(filter),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

//api/central/admins/settlements
export const addPayment = async (data) => {
  return await axios
    .post(`${AdminURL}/admins/settlements`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

///api/central/admins/settlements/show/1
export const paymentListShow = async (id) => {
  return await axios
    .get(`${AdminURL}/admins/settlements/show/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

//auth/forget-password

export const forgetPassword = async (data) => {
  return await axios
    .post(`${AdminURL}/auth/forget-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
//resetPassword

export const resetPassword = async (data) => {
  return await axios
    .post(`${AdminURL}/auth/reset-password`, data)
    .then((res) => res)
    .catch((err) => err.response);
};
export const permissionsReload = async () => {
  return await axios
    .get(`${AdminURL}/auth/me`)
    .then((res) => res)
    .catch((err) => err.response);
};
///notifications
export const allNotifications = async (page) => {
  return await axios
    .get(`${AdminURL}/notifications?page= ${page}`)
    .then((res) => res)
    .catch((err) => err.response);
};
///read-notification

export const readNotification = async (id) => {
  return await axios
    .get(`${AdminURL}/read-notification/${id}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const allSettlement = async (params, id) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/saudi/settlements`, {
      params: cleanEmpty(params),
    })
    .then((res) => res)
    .catch((err) => err.response);
};

export const creatReceipt = async (data, id) => {
  return await axios
    .post(`${AdminURL}/companies/${id}/saudi/settlements`, data)
    .then((res) => res)
    .catch((err) => err.response);
};

export const allReceipts = async (id) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/saudi/receipts`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const acceptReceipt = async (id, receiptId) => {
  return await axios
    .patch(`${AdminURL}/companies/${id}/saudi/receipts/${receiptId}`)
    .then((res) => res)
    .catch((err) => err.response);
};
// https://yarveldh.com/api/central/lookups/areas?city_id=4

export const areasListAdmin = async (id) => {
  return await axios
    .get(`${AdminURL}/lookups/areas?city_id=${id}`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const fetchHotelsSaudiAdmin = async (id) => {
  return await axios
    .get(AdminURL + `/lookups/hotels?area_id=${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const printReceipts = async (id, receiptId) => {
  return await axios
    .get(`${AdminURL}/companies/${id}/saudi/receipts/print/${receiptId}`)
    .then((res) => res)
    .catch((err) => err.response);
};

export const listPackagesU = async (params) => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const listCompanies = async () => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages/companies`)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const addCompaniesPackages = async (data) => {
  return await axios
    .post(AdminURL + `/companies/saudi-packages`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const listTravelersAdmin = async (id, params, status) => {
  return await axios
    .get(
      AdminURL +
        `/companies/${id}/saudi-travellers${status ? "/" + status : ""}`,
      {
        params: cleanEmpty(params),
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const viewTravelersAdmin = async (id, params, traveler_id) => {
  return await axios
    .get(AdminURL + `/companies/${id}/saudi-travellers/view/${traveler_id}`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const viewTravelersAdminOutStatus = async (id, params, traveler_id) => {
  return await axios
    .get(AdminURL + `/companies/${id}/saudi-travellers/${traveler_id}`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const saveReservationResultAdmin = async (id, company_id, data) => {
  return await axios
    .post(AdminURL + `/companies/${company_id}/saudi-travellers/${id}`, data)
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const exportExeclAdmin = async (id, params, status) => {
  return await axios
    .get(
      AdminURL +
        `/companies/${id}/saudi-travellers/export${status ? "/" + status : ""}`,
      {
        responseType: "blob",
        params: cleanEmpty(params),
      }
    )
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const exportExeclViewAdmin = async (id, params, traveler_id) => {
  return await axios
    .get(AdminURL + `/companies/${id}/saudi-travellers/export/${traveler_id}`, {
      responseType: "blob",
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const exportExeclUmrahPackages = async (params) => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages/export`, {
      responseType: "blob",
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};

export const viewSaudiPackages = async (id) => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages/${id}`)
    .then((res) => res.data.data)
    .catch((err) => err.response);
};

export const getLatedAdmin = async (params) => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages/delayed`, {
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};
export const exportExeclLatedPilgramsAdmin = async (params) => {
  return await axios
    .get(AdminURL + `/companies/saudi-packages/delayed/export`, {
      responseType: "blob",
      params: cleanEmpty(params),
    })
    .then((res) => res.data)
    .catch((err) => err.response);
};
