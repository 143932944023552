import React from "react";
import Locale from "../../../translations";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import ShowForPermission from "../../../helpers/showForPermission";
import { useCompanyState } from "../../../context/global";
import Checkbox from "../../../components/shared/checkbox";

export default function Request({ request, toggle, handleChecks, operation }) {
  const { translate } = Locale;
  const { status } = useParams();
  const { currentLanguage } = useCompanyState();

  return (
    <tr className="lan">
      <td>
        <Checkbox value={request?.id} name="check" onChange={handleChecks} />
      </td>
      <td>{request?.package_name}</td>
      <td>{request?.company_name[currentLanguage]}</td>

      <td>{request?.travellers_count}</td>
      <td>{request?.hotel?.name}</td>
      <td>{request?.tenant_name}</td>
      <td>
        <p className="text-success font-weight-bold m-0">
          {request?.hotel?.entry_date
            ? moment(request?.hotel?.entry_date, "DD-MM-YYYY HH:mm aa").format(
                "DD-MM-YYYY"
              )
            : " "}
        </p>

        <p className="text-danger font-weight-bold">
          {request?.hotel?.exit_date
            ? moment(request?.hotel?.exit_date, "DD-MM-YYYY HH:mm aa").format(
                "DD-MM-YYYY"
              )
            : " "}
        </p>
      </td>
      <td>{request?.hotel?.city}</td>
      <td>{request?.SaSupervisor?.name}</td>

      <td>
        {operation.packages_ids.includes(request?.id)}
        <button
          className="btn text-primary font-weight-bold"
          disabled={operation.packages_ids.includes(request?.id)}
          onClick={() => {
            toggle(request);
          }}
        >
          {translate.supplyTrip}
        </button>

        <Link
          className="btn text-primary font-weight-bold"
          to={`/admin/Umrah/Medical/packages/view/${request.id}`}
        >
          {translate.view}
        </Link>
      </td>
    </tr>
  );
}
